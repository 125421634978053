@font-face {
    font-family: ProximaNova-Thin;
    src: url("../../fonts/proxima-nova/ProximaNovaCond-Thin.otf") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova-Light;
    src: url("../../fonts/proxima-nova/ProximaNova-Light.otf"),
        url("../../fonts/proxima-nova/proximanovacond-light-webfont.eot?#iefix") format("embedded-opentype");
}

@font-face {
    font-family: ProximaNova-Regular;
    src: url("../../fonts/proxima-nova/ProximaNova-Reg.otf"),
        url("../../fonts/proxima-nova/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova-Medium;
    src: url("../../fonts/proxima-nova/proximanova-medium.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova-Semibold;
    src: url("../../fonts/proxima-nova/ProximaNova-Semibold.otf"),
        url("../../fonts/proxima-nova/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova-Bold;
    src: url("../../fonts/proxima-nova/ProximaNova-Bold.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GothamRounded-Bold;
    src: url("../../fonts/gotham-rounded/GothamRounded-Bold.otf") , url('../../fonts/GothamRounded-Bold.eot?#iefix') format('embedded-opentype');
}