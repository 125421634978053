@import '../../assets/styles/themes/font.less';

.sanlam{
    --primary-header-background-color: #222631;
    --primary-white-font-color: #FFFFFF;
    --primary-text-font-color: #2C3241;
    --primary-app-background-color: #F5F8FA;
    --primary-divider-color: #E7E7E7;

    //font color related variables
    --primary-font-color: #2C3241;
    --primary-white-font-color: #FFFFFF;
    --primary-black-font-color: #000000;
    --primary-card-font-color: #1E1E1E;
    --primary-title-font-color: #333333;
    --primary-description-font-color: #898989;
    --guest-footer-color: #9E9E9E;
    --faq-que-font-color: #383838;
    
    //font family related variables
    --primary-proxima-thin: ProximaNova-Thin;
    --primary-proxima-light: ProximaNova-Light;
    --primary-proxima-regular: ProximaNova-Regular;
    --primary-proxima-medium: ProximaNova-Medium;
    --primary-proxima-semibold: ProximaNova-Semibold;
    --primary-proxima-bold: ProximaNova-Bold;
    --primary-gotham-bold: GothamRounded-Bold;

    //font size related variables
    --primary-letter-spacing: 0px;
    --primary-font-S12: 12px;
    --primary-font-S14: 14px;
    --primary-font-S15: 15px;
    --primary-font-S16: 16px;
    --primary-font-S18: 18px;
    --primary-font-S20: 20px;
    --primary-font-S22: 22px;
    --primary-font-S24: 24px;
    --primary-font-S28: 28px;
    --primary-font-S32: 32px;
    --primary-font-S40: 40px;

    //button-color-codes
    --primary-bg-color : #50C9E3;
    --primary-br-color: #48B8D0;
    --success-bg-color: #0db26f;
    --success-br-color: #1a994a;
    --success-secondary-bg-color: #4CDCBF;
    --success-secondary-br-color: #4CDCBF;
    --error-bg-color: #d91925;
    --error-br-color: #b2121c;
    --warning-bg-color: #d95c1b;
    --warning-br-color: #ff7127;
    --btn-award-bg-color: #7993EF;
    --btn-award-br-color: #7993EF;
    --btn-reward-bg-color: #F5563B;
    --btn-reward-br-color: #F5563B;
    --btn-preview-bg-color: #3E4557;
    --btn-preview-br-color: #3E4557;
    --btn-location-bg-color: #75BEF0;

    //checkbox background color
    --checked-checkbox--background-color:#50c9e3;

    // fields related variables
    --field-border-color:#dfe6ea;
    --field-title-color:#848f9a;
    --field-value-color:#3b4151;
    //input-field-color-palate
    --input-placeholder-color: #848F9A;
    --field-bg-color : #FFFFFF;

    // toggle-field-related-variables
    --inactive-switch-background-color:#454b5c;
    --inactive-switch-border-color:#373c4a;
    --inactive-switch-inner-color:#FFFFFF;
    --active-switch-background-color:#50c9e3;
    --active-switch-border-color:#48b8d0;
    --active-switch-inner-color:#FFFFFF;
    --deactivate-switch-background-color: #E34242;
    --deactivate-switch-border-color: #CC3B3B; 

    // placeholder related variables
    --placeholder-background-color:#F5F6F7;
    --placeholder-border-color:#D6DCE0;

    
    //input-field-color-palate
    --input-text-color: #3B4151;
    --input-placeholder-color: #848F9A;
    --input-bg-color : #FFFFFF;
    --input-border-color : #DFE6EA;

    // icon-button-colr
    --add-icon-buttom-bg-color:#50C9E3;
    --remove-icon-buttom-bg-color:#F15151;
    --cross-icon-buttom-bg-color:#4E5466;
    --check-icon-buttom-bg-color:#50E383;

    //status codes
    --launched-bg-color: #31EF6A;
    --expired-bg-color: #d4d4d4;
    --terminated-bg-color: #415064;
    --planning-bg-color: #FF7127; 
    --test-bg-color: #ffdc34;

    //modal codes
    --modal-header-background-color: #292f3d;
    //listing page
    --offer-label-bg-color: #e6edef;
    --segment-label-bg-color: #E9F8FF;

    //Calendar

    --calendar-header-background-color: #292f3d;
    --calendar-header-text-color :#ffffff;

    //locations
    --warning-container-bg-color: #ff7127;
    --warning-container-border-color: #c7581e;




}

.generic {
    --primary-header-background-color: #222631;
    --primary-white-font-color: #FFFFFF;
    --primary-text-font-color: #2C3241;
    --primary-app-background-color: #F5F8FA;
    --primary-divider-color: #E7E7E7;

    //font color related variables
    --primary-font-color: #2C3241;
    --primary-white-font-color: #FFFFFF;
    --primary-black-font-color: #000000;
    --primary-card-font-color: #1E1E1E;
    --primary-title-font-color: #333333;
    --primary-description-font-color: #898989;
    --guest-footer-color: #9E9E9E;
    --faq-que-font-color: #383838;
    
    //font family related variables
    --primary-proxima-thin: ProximaNova-Thin;
    --primary-proxima-light: ProximaNova-Light;
    --primary-proxima-regular: ProximaNova-Regular;
    --primary-proxima-medium: ProximaNova-Medium;
    --primary-proxima-semibold: ProximaNova-Semibold;
    --primary-proxima-bold: ProximaNova-Bold;
    --primary-gotham-bold: GothamRounded-Bold;

    //font size related variables
    --primary-letter-spacing: 0px;
    --primary-font-S12: 12px;
    --primary-font-S14: 14px;
    --primary-font-S15: 15px;
    --primary-font-S16: 16px;
    --primary-font-S18: 18px;
    --primary-font-S20: 20px;
    --primary-font-S22: 22px;
    --primary-font-S24: 24px;
    --primary-font-S28: 28px;
    --primary-font-S32: 32px;
    --primary-font-S40: 40px;

    //button-color-codes
    --primary-bg-color : #50C9E3;
    --primary-br-color: #48B8D0;
    --success-bg-color: #0db26f;
    --success-br-color: #1a994a;
    --success-secondary-bg-color: #4CDCBF;
    --success-secondary-br-color: #4CDCBF;
    --error-bg-color: #d91925;
    --error-br-color: #b2121c;
    --warning-bg-color: #d95c1b;
    --warning-br-color: #ff7127;
    --btn-award-bg-color: #7993EF;
    --btn-award-br-color: #7993EF;
    --btn-reward-bg-color: #F5563B;
    --btn-reward-br-color: #F5563B;
    --btn-preview-bg-color: #3E4557;
    --btn-preview-br-color: #3E4557;
    --btn-location-bg-color: #75BEF0;

    //checkbox background color
    --checked-checkbox--background-color:#50c9e3;

    // fields related variables
    --field-border-color:#dfe6ea;
    --field-title-color:#848f9a;
    --field-value-color:#3b4151;
    //input-field-color-palate
    --input-placeholder-color: #848F9A;
    --field-bg-color : #FFFFFF;

    // toggle-field-related-variables
    --inactive-switch-background-color:#454b5c;
    --inactive-switch-border-color:#373c4a;
    --inactive-switch-inner-color:#FFFFFF;
    --active-switch-background-color:#50c9e3;
    --active-switch-border-color:#48b8d0;
    --active-switch-inner-color:#FFFFFF;
    --deactivate-switch-background-color: #E34242;
    --deactivate-switch-border-color: #CC3B3B; 

    // placeholder related variables
    --placeholder-background-color:#F5F6F7;
    --placeholder-border-color:#D6DCE0;

    
    //input-field-color-palate
    --input-text-color: #3B4151;
    --input-placeholder-color: #848F9A;
    --input-bg-color : #FFFFFF;
    --input-border-color : #DFE6EA;

    // icon-button-colr
    --add-icon-buttom-bg-color:#50C9E3;
    --remove-icon-buttom-bg-color:#F15151;
    --cross-icon-buttom-bg-color:#4E5466;
    --check-icon-buttom-bg-color:#50E383;

    //status codes
    --launched-bg-color: #31EF6A;
    --expired-bg-color: #d4d4d4;
    --terminated-bg-color: #415064;
    --planning-bg-color: #FF7127; 
    --test-bg-color: #ffdc34;

    //modal codes
    --modal-header-background-color: #292f3d;
    //listing page
    --offer-label-bg-color: #e6edef;
    --segment-label-bg-color: #E9F8FF;

    //Calendar

    --calendar-header-background-color: #292f3d;
    --calendar-header-text-color :#ffffff;

    //locations
    --warning-container-bg-color: #ff7127;
    --warning-container-border-color: #c7581e;




}