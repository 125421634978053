/* You can add global styles to this file, and also import other style files */
@import '../src/assets/styles/variables.less';
@import '../src/assets/styles/themes/mixins.less';

html, body { height: 100%; }
body { margin: 0; font-family: ProximaNova-Regular, 'Helvetica Neue', Arial, sans-serif }
input {
    border: none;
}

* {
    -webkit-font-smoothing: antialiased;
}

.backdropBackground {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(16px);
    background-color: rgb(0 0 0 / 60%);
}

.custom-dialog-container .mat-dialog-container .mdc-dialog--open .mat-mdc-dialog-surface, .mdc-dialog--closing .mat-mdc-dialog-surface {
    padding: 0px !important;
    border-radius: 12px !important;
    opacity: 1;
    background: #f8f8f8;
}
.mdc-dialog--open .mat-mdc-dialog-surface, .mdc-dialog--closing .mat-mdc-dialog-surface {
    border-radius: 12px !important;
}

#tnc-content p {
    margin: 0px;
}

#tnc-content a {
    color: #50C9E3;
}

#nav-content p {
    margin: 0px;
    color: #9E9E9E;
}

#nav-content a {
    color: #9E9E9E;
    text-decoration: none;
}

.pac-container {
    // width: calc(100% - 440px) !important;
    position: absolute;
    // left: 275px !important;
    // right: 275px !important;
    left: calc(100% - 71px);
    background-color: var(--primary-white-font-color);
    border-radius: 12px 12px;
    padding-top: 0px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid rgb(223, 230, 234) !important;
    border-top: none;
    box-shadow: none !important;
    .merchant-text-style(var(--primary-font-color), var(--primary-proxima-medium), var(--primary-font-S14), 16px, var(--primary-letter-spacing)) !important;
}

.pac-item {
    border-top: none !important;
    border-bottom: 1px solid #e6e6e6 !important;
    cursor: pointer !important;
}

.hdpi.pac-logo:after {
    margin-top: 7px;
}
// To Handle Popup-over-popup case

.hidable-backdrop {
    opacity: 0 !important;
    &:first-child {
      opacity: 0.8 !important;
    }
  }
  .cdk-global-overlay-wrapper {
    .hidable-panel {
      display: none;
    }
    &:last-child {
      .hidable-panel {
        display: flex;
      }
    }
  }

// @media (min-width: 1450px) and (max-width: 1792px) {
//     .pac-container {
//         width: calc((1297 / 1792) * 100%) !important;
//     }
// }